/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden !important;
}

h1, h2, h3, h4, p {
    margin: 0;
}

:root {
  --main-background-color: #E8E1DA;
  --modal-background-color: #f5ebe0;
  --main-text-color: #f5ebe0;
  --primary-color: #aa7450;
  --secondary-color: #aa7450;
}

.app-titre-component {
  background-color: var(--main-background-color);
  color: var(--primary-color);
  font-weight: bold;
  font-size: 18px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px;
}

mat-icon {
  margin-right: 8px;
  color: var(--primary-color) !important;
}

.contenue{
  margin-top: 5% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  flex: 1 !important;
  overflow-y: auto !important;
}

.list-container {
  padding: 20px !important;
}

.container {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  justify-content: space-between !important;
  color: var(--primary-color) !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

mat-table {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 10PX  0 10px 0;
}

mat-paginator {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 10PX  0 10px 0;
  color: var(--primary-color) !important;
}

mat-mdc-paginator-container{
  color: var(--primary-color) !important;
}

mat-select {
  color: var(--primary-color) !important;
}

mat-header-row, mat-header-cell {
  background-color: var(--modal-background-color) !important;
  font-weight: bold !important;
  border-bottom: 1px solid var(--main-text-color) !important;
  color: var(--primary-color);
}

mat-row, mat-cell {
  border-bottom: 1px solid var(--primary-color) !important;
  color: var(--primary-color);
}

mat-row:hover {
  background-color: var(--main-background-color) !important;
}

.uniform-size {
  flex: 1 1 calc(15% - 10px) !important;
  height: 56px !important;
  margin-bottom: 10px !important;
  background-color: var(--main-background-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--modal-background-color) !important;
  border-radius: 4px !important;
}

@media (max-width: 768px) {
  .uniform-size {
    flex: 1 1 100% !important;
  }
}

.example-button-row {
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
  gap: 10px !important;
}

.modal {
  width: 70vw; /* 70% de la largeur de l'écran */
  max-height: 90vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  box-sizing: border-box;
  background-color: var(--modal-background-color);
  border-radius: 10px;
  color: var(--main-text-color);
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-row .mat-form-field {
  flex: 1;
  min-width: calc(50% - 10px);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .form-row .mat-form-field {
    min-width: 100%;
  }
}

@media (max-width: 480px) {
  .form-row .mat-form-field {
    min-width: 100%;
  }
}

.modal-content {
  background-color: var(--modal-background-color) !important;
  border-radius: 10px !important;
}

.modal-header{
  color: var(--primary-color) !important;
  font-size: 23px !important;
  text-align: center !important;
  padding: 10px !important;
}

.dialog-actions {
  justify-content: center;
  color: var(--primary-color);
}

.custom-close-button {
  background-color: var(--main-background-color);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: var(--main-text-color);
  cursor: pointer;
}

.custom-close-button:hover {
  background-color: var(--secondary-color);
}

mat-form-field input[type="number"]::-webkit-outer-spin-button,
mat-form-field input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-form-field {
  display: block;
  width: 100%;
}

.custom-form-field.mat-form-field {
  margin-bottom: 1rem;
}

.custom-form-field .mat-form-field-wrapper {
  padding-bottom: 1rem; /* Adjust if needed */
}

.custom-form-field .mat-form-field-flex {
  align-items: baseline;
}

.custom-form-field .mat-form-field-label-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appInputChamp {
  height: 58px !important;
  margin-bottom: 37px !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 4px !important;
  padding-left: 5px;
  width: 100%;
}

.appSumChamp {
  height: 58px !important;
  margin-bottom: 37px !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 4px !important;
  font-size: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}